import React from 'react';
import { graphql } from 'gatsby';

import ContactDetails from '../sections/contactDetails';
import Episodes from '../sections/episodes';
import Hero, { PlayButton, Text, Tagline, Title } from '../sections/hero/hero';
import Layout from '../components/layout/layout';

import shareImage from '../assets/images/podcast-social-sharing.jpg';

export default function PodcastEpisodes(props) {
  const backgroundImage = props.data.file.childImageSharp.gatsbyImageData;
  const recentEpisodeUrl =
    props.data.allPodcastRssFeedEpisode.edges[0].node.item.enclosure.url;

  return (
    <Layout
      title="Digital Insurance Podcast"
      tagline="Gespräche zur digitalen Transformation in der Versicherungswirtschaft"
      description="Mit Managern etablierter Unternehmen rede ich über die Herausforderungen der digitalen Transformation in der Versicherungswirtschaft. Neue Ausgaben erscheinen am ersten Donnerstag im Monat."
      shareImage={shareImage}
      pathname={props.location.pathname}
    >
      <Hero backgroundImage={backgroundImage}>
        <Tagline>Digital Insurance Podcast</Tagline>
        <Title>
          Gespräche zur digitalen Transformation in der Versicherungswirtschaft
        </Title>
        <Text>
          {' '}
          Neue Ausgaben erscheinen am ersten Donnerstag im Monat hier oder bei{' '}
          <a
            href="https://podcasts.apple.com/de/podcast/digital-insurance-podcast/id1498593777"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple
          </a>
          ,{' '}
          <a
            href="https://podcasts.google.com/?feed=aHR0cDovL3BvZGNhc3QtYWRtaW4uZmlyZWJhc2VhcHAuY29tL2ZlZWQvMlQ5YVZxRUpodmQxRmNrMnZWUHAv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google
          </a>
          ,{' '}
          <a
            href="https://open.spotify.com/show/0Oh8uJFbxX066lBPdCGehW"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify
          </a>{' '}
          und Co.
        </Text>
        <PlayButton src={recentEpisodeUrl}>
          Aktuelle Ausgabe abspielen
        </PlayButton>
      </Hero>
      <Episodes tag={props?.pageContext?.tag} />
      <ContactDetails
        title="Wir suchen immer neue, spannende Gesprächspartner"
        profiles={[props.data.susan]}
      />
    </Layout>
  );
}
export const query = graphql`
  query PodcastEpisodesQuery {
    file(relativePath: { eq: "backgrounds/bg-podcast.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    allPodcastRssFeedEpisode(
      sort: { fields: item___isoDate, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          item {
            enclosure {
              url
            }
          }
        }
      }
    }
    susan: teamJson(name: { eq: "Susan Bröder" }) {
      name
      bio
      focus
      city
      country
      bookingLink
      linkedin
      email
      image {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 200, layout: CONSTRAINED)
        }
      }
    }
  }
`;
